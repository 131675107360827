<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>
          <v-row>
            <v-select
              :items="canalOptions"
              v-model="canalSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canal"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-autocomplete
              :items="asociadoOptions"
              v-model="asociadoSelected"
              item-text="Nombre"
              item-value="AsociadoId"
              placeholder="Asociado"
              append-icon="keyboard_arrow_down"
              hint="Asociado"
              persistent-hint
              clearable
            ></v-autocomplete>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="processing"
      ></v-progress-linear>
      <v-tabs>
         <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab>Reporte</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="chartData"
                :items-per-page="10"
              >
                <template v-slot:item.Total="{ item }">{{
                  item.Total | numeric
                }}</template>
                <template v-slot:item.TotalNeto="{ item }">{{
                  item.TotalNeto | numeric
                }}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert dense type="warning" text v-show="!processing && isChartEmpty"
        >No existe información para esta gráfica</v-alert
      >
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themesAnimated);

export default {
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      canalSelected: null,
      periodoSelected: null,
      asociadoSelected: null,
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "MONTO", value: "Total" },
        { text: "LIQUIDEZ", value: "Liquidez" },
        { text: "TOTAL NETO", value: "TotalNeto" },
      ],
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => c.CanalId == this.canalSelected
      );
    },
    canalOptions() {
      return this.$store.getters.canalesExternos;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 10;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        let conceptos = [];
        var periodos = [];
        dataParam.forEach(function (row) {
          conceptos.push(row.Concepto);
          periodos.push(row.Periodo);
        });

        periodos.forEach(function (d) {
          let rows = dataParam.filter((f) => f.Periodo === d);
          let newRow = {
            Periodo: d,
            TotalNeto: 0,
            Total: 0,
            Liquidez: 0,
          };
          let total = 0;
          let totalNeto = 0;
          rows.forEach(function (c) {
            if (c) {
              if (c.Concepto != "Descuentos") {
                totalNeto += c.Monto;
              }
              total += c.Monto;
            }
          });
          newRow["TotalNeto"] = totalNeto;
          newRow["Total"] = total;
          newRow["Liquidez"] = ((total / totalNeto) * 100).toFixed(2);
          data.push(newRow);
        });

        this.chartData = data;
        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "Periodo";
        series.dataFields.valueY = "TotalNeto";
        series.name = "Total neto";
        series.stacked = true;
        series.stroke = am4core.color("#8B008B");
        series.columns.template.fill = am4core.color("#8B008B");
        series.columns.template.width = am4core.percent(80);
        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Periodo";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "% Liquidez";
        lineSeries.strokeWidth = 3;
        var bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.propertyFields.rotation = "angle";
        var arrow = bullet.createChild(am4core.Triangle);
        arrow.horizontalCenter = "middle";
        arrow.verticalCenter = "middle";
        arrow.stroke = am4core.color("#fff");
        arrow.direction = "top";
        arrow.width = 10;
        arrow.height = 25;

        var hoverState = bullet.states.create("hover");
        hoverState.properties.scale = 1.9;
        bullet.tooltipText = "{name}: [bold]{Liquidez} ({Total})[/]";

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.periodoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          AsociadoId: this.asociadoSelected,
          CanalID: this.canalSelected,
        };

        apiDashboard
          .getGetChartLiquidez(param)
          .then((response) => {
            this.generateChart(response.data);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
  },
  watch: {
    canalSelected: function () {
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    asociadoSelected: function () {
      this.refresh();
    },
  },
  mounted() {
    if (this.canalOptions.length > 0) {
      this.canalSelected = this.canalOptions[0].CanalId;
    }
    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>